<template>
  <div class="detail">
    <button 
      class="detail-btn"
      :class="{'detail-btn--on-map': btnMap}"
    >
      {{ btnText }}
      <div class="arrow">
        <arrow class="detail-arrow"/>
      </div>
    </button>
  </div>
</template>

<script>
import Arrow from "@/components/Icons/ArrowRight";

export default {
  components: {
    Arrow,
  },
  props: {
    btnText: {
      type: String,
      default: ""
    },
    btnMap: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-btn {
  display: flex;
  align-items: center;
  padding: 28px 18px 28px 41px;
  margin-top: 36px;
  margin-left: 124px;
  font-size: 44px;
  font-weight: normal;
  border: none;
  border-radius: 67px;
  outline: none;
  background-color: #2a2a2c;

  &--on-map {
    margin-left: 0;
    color: #000;
    background-color: #fff;
  }
}
.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin-left: 24px;
  border-radius: 50%;
  background-color: #0b83fe;
}
</style>